<template>
    <b-modal id="form-select" centered hide-header hide-footer>
        <button @click="$bvModal.hide('form-select')" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="py-4 px-3 text-center">
            <p class="vc-title">
                {{ $t("facility.fee.amount") }}
            </p>
            <form @submit.prevent="submit">
                <b-form-input
                    v-model="data.fee"
                    type="number"
                    :min="0"
                    autofocus
                    required
                    onkeydown="return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'"
                ></b-form-input>
                <button class="btn btn-success mt-3" type="submit">
                    {{ $t("guest.global.confirm") }}
                </button>
            </form>
        </div>
    </b-modal>
</template>

<script>
export default {
    name: "input-fee-single-date.component.vue",
    data() {
        return {
            data: {
                fee: 0
            }
        }
    },

    methods: {

        async submit(){

            this.$emit('event',this.data.fee);


        },
        async set_fee(fee){

            this.data.fee = fee;
            console.log(    this.data.fee);
            console.log(    1);
        }
    }
}
</script>

<style scoped>

</style>