import axios from 'axios'
import config from '../../config/app.config';

var promise;

export default class GoogleService {
    holidays(location,params) {
        promise = axios.get(`${config.google_api.url}/calendar/v3/calendars/${location}/events?key=${config.google_api.key}`, {params: params});
        return promise;
    }
}

export const googleService = new GoogleService();
