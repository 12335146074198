import { alertService } from "@/services/alert/alert.service";
import FullCalendar from "@fullcalendar/vue";
import allLocales from "@fullcalendar/core/locales-all";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import config from "../../../../config/app.config";
import { facilityInventoryService } from "../../../../services/facility/inventory/inventory.service";
import InputFeeSelectedDateComponent from "./input-fee-selected-date.component";
import InputFeeBatchDateComponent from "./input-fee-batch-date.component";
import { reservationFeeService } from "../../../../services/facility/reservation-fee/reservation-fee.service";
import { EventBus } from "../../../../event-bus";
import { googleService } from "../../../../services/google/google.service";
import moment from "moment";

export default {
  components: {
    FullCalendar,
    InputFeeSelectedDateComponent,
    InputFeeBatchDateComponent,
  },
  data() {
    return {
      data: {
        date_fee: [],
      },
      error: {},
      config: {},
      calendar_options: {
        dayCellDidMount: this.init_days,
        locales: allLocales,
        locale: this.$i18n.locale,
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        selectable: true,
        selectOverlap: false,
        select: this.handle_date_select,
        eventClick: this.handle_event_click,
        eventOverlap: false,
        events: [],
        eventContent: this.init_calendar,
        validRange: {},
        datesSet: this.handle_change_month,
      },
      filter: {
        paginate: 0,
        per_page: 1,
        airport_id: config.airport_id,
      },
      parkings: [],
      available_dates: [],
      calendar: {},
      day_cells: [],
    };
  },
  async mounted() {
    EventBus.$on("changeLocal", () => {
      this.calendar_options.locale = this.$i18n.locale;
    });

    await this.init_parking(this.filter);
  },
  methods: {
    async submit() {
      console.log(this.data);
      var callback = async () => (this.data.id ? this.update() : this.store());

      alertService.confirmation(callback, this.$t("facility.global.confirmation"), this.$t("facility.global.are-you-sure"));
    },
    async store() {
      let data = {
        ...this.data,
        date_fee: await this.populate_save_fee(this.data.date_fee),
      };
      await reservationFeeService.store(data).then((result) => {
        alertService.success(this.$t("facility.global.success"), this.$t("facility.global.success"));
      });
    },
    async update() {
      let data = {
        ...this.data,
        date_fee: await this.populate_save_fee(this.data.date_fee),
      };

      await reservationFeeService.update(this.data.id, data).then((result) => {
        alertService.success(this.$t("facility.global.success"), this.$t("facility.global.updated"));
      });
    },
    async show(data) {
      let result = await reservationFeeService.list(data);

      if (result.data.data.length == 1) {
        this.data = result.data.data[0];
        console.log(this.data);
        this.data.date_fee.forEach(async (value, key) => {
          this.calendar.startStr = value.date;
          await this.set_fee_selected_date(value.fee);
        });
      }
    },
    async populate_save_fee(data) {
      let fee = [];
      data.forEach((value, key) => {
        if (value.fee) {
          fee.push(value);
        }
      });
      return fee;
    },
    async selected_parking() {
      this.calendar_options.events = [];
      this.data.date_fee = [];
      let parking = this.parkings.find((el) => el.id == this.data.parking_id);

      this.available_dates = parking.reservable_capacities;

      this.available_dates.forEach(async (value, key) => {
        this.calendar.startStr = value.date;
        await this.set_fee_selected_date("");
      });

      await this.show({
        per_page: 1,
        paginate: 1,
        parking_id: this.data.parking_id,
      });
      alertService.close();
    },
    async set_fee_selected_date($event, start_date = undefined) {
      let start_Date = start_date ? start_date : this.calendar.startStr;
      let el = this.calendar_options.events.findIndex((el) => el.start == start_Date);
      //   let fee = parseInt($event);
      let fee = $event;
      if (el >= 0) {
        let exist = this.data.date_fee.findIndex((e) => e.date == start_Date);
        this.calendar_options.events[el].title = fee;
        if (exist >= 0) {
          this.data.date_fee[exist].fee = fee;
        } else {
          this.data.date_fee.push({
            date: start_Date,
            fee: fee,
          });
        }
      } else {
        this.calendar_options.events.push({
          start: start_Date,
          end: start_Date,
          title: fee,
        });
        this.data.date_fee.push({
          date: start_Date,
          fee: fee,
        });
      }
      this.$refs.selected.$bvModal.hide("form-select");
    },

    async set_fee_batch_date($event) {
      $event.dates.forEach(async (value, key) => {
        await this.set_fee_selected_date($event.fee, value.id);
      });
      this.$refs.selected.$bvModal.show("form-batch");
    },

    async init_parking(query) {
      alertService.loading();
      let response = await facilityInventoryService.list(query);
      this.parkings = response.data.data;
      if (this.parkings.length > 0) {
        this.data.parking_id = this.parkings[0].id;
        await this.selected_parking();
      } else {
        alertService.close();
      }
    },

    async handle_date_select($event) {
      let exist = this.available_dates.find((el) => el.date == $event.startStr);

      if (exist) {
        this.calendar = $event;
        await this.handle_set_fee(exist);
        this.$refs.selected.$bvModal.show("form-select");
      }
    },
    async handle_set_fee(data) {
      let fee = 0;
      let result = this.data.date_fee.find((el) => el.date == data.date);
      if (result) {
        fee = result.fee;
      }
      this.$refs.selected.set_fee(fee);
    },
    async handle_event_click($event) {
      let exist = this.available_dates.find((el) => el.date == $event.event.startStr);

      if (exist) {
        this.calendar = $event.event;
        await this.handle_set_fee(exist);
        this.$refs.selected.$bvModal.show("form-select");
      }
    },
    async handle_change_month($event) {
      let query = {
        timeMax: $event.endStr,
        timeMin: $event.startStr,
      };

      let result = await googleService.holidays("en.japanese%23holiday%40group.v.calendar.google.com", query);

      result.data.items.forEach(async (value, key) => {
        let result = this.day_cells[value.start.date];
        if (result) {
          result.el.classList.add("holiday");
        }
      });
    },
    init_calendar($event) {
      return { html: `<strong>${$event.event.title}</strong` };
    },
    async init_days(date) {
      let date_str = this.formatYMD(date.date);

      this.day_cells[date_str] = {
        ...date,
        date_str: date_str,
      };
    },
  },
};
