<template>
    <b-modal id="form-batch" centered hide-header hide-footer>
        <button @click="$bvModal.hide('form-batch')" class="close">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="py-4 px-3 text-center">
            <p class="vc-title">
                 {{ $t("facility.fee.select-the-date") }}
            </p>
            <vc-calendar
                ref="calendar"
                is-expanded
                :disabled-dates="config.disable_dates"
                :available-dates="config.available_dates"
                :attributes="config.dates"
                @dayclick="on_day_click"
                @update:from-page="init_month_year"
            />

            <form @submit.prevent="submit">
                <div class="d-flex justify-content-center my-3">
                    <button class="btn btn-primary mr-1" @click="select_all" type="button">
                         {{ $t("facility.fee.select-all") }}
                    </button>

                    <div class="form-group mb-0">
                        <div class="col-12">
                            <input type="number" v-model="data.fee" class="form-control" onkeydown="return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'" required/>
                        </div>
                    </div>
                </div>
                <button class="btn btn-success mr-1" type="submit">
                     {{ $t("guest.global.confirm") }}
                </button>
            </form>
        </div>
    </b-modal>
</template>

<script>
import moment from "moment";

export default {
    name: "input-fee-single-date.component.vue",
    data() {
        return {
            data: {},
            available_dates: [],
            config: {
                disable_dates: {weekdays: [1, 2, 3, 4, 5, 6, 7]},
                available_dates: [],
                dates: []
            },

        }
    },
    computed: {},
    methods: {

        async submit() {

            this.$emit('event', {fee: this.data.fee, dates: this.config.dates});
            this.$bvModal.hide('form-batch');
        },
        on_day_click($event) {
            if(!$event.isDisabled){
                const index = this.config.dates.findIndex(el => el.id === $event.id);
                if (index >= 0) {
                    this.config.dates.splice(index, 1);
                } else {
                    this.config.dates.push({
                        id: $event.id,
                        dates: $event.date,
                        highlight: true,
                    });

                }
            }

        },
        select_all() {
            let days_in_month = moment(this.config.year_month, "YYYY-MM").daysInMonth();
            for (let index = 0; index < days_in_month; index++) {
                let day = index + 1;
                day = ('0' + day).slice(-2);
                let date = `${this.config.year_month}-${day}`;
                let exist_in_available_date = this.available_dates.find(el => el.date == date);

                if (exist_in_available_date) {
                    let exist = this.config.dates.find(el => el.id == date);
                    if (!exist) {
                        this.config.dates.push({
                            id: date,
                            dates: new Date(date),
                            highlight: true,
                        });
                    }
                }


            }
        },
        init_month_year($event) {
            this.config.year_month = moment(`${$event.year}-${$event.month}`).format('Y-MM');

        },
        async init(data) {
            this.config.dates = [];
            this.config.available_dates = [];
            this.data = {};

            this.available_dates = data;
            this.available_dates.forEach((value, key) => {
                this.config.available_dates.push(
                    {
                        start: value.date,
                        end: value.date,
                    }
                );
            });
            this.$bvModal.show('form-batch');
        }
    }
}
</script>

<style scoped>

</style>