import api from '../../../globals/client';
import config from '../../../config/app.config';

var promise;

export default class ReservationFeeService {
    store(data) {
        promise =  api.client.post(config.end_point.facility.reservation_fees, data);
        return promise;
    }

    list(data) {
        promise =  api.client.get(config.end_point.facility.reservation_fees, {params: data});
        return promise;
    }

    update(id,data) {
        promise =  api.client.put(config.end_point.facility.reservation_fees+'/'+id, data);
        return promise;
    }
}

export const reservationFeeService = new ReservationFeeService();
